<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-history-back">
          <a href="javascript:" @click="goBack" class="util-actions util-actions-history-back">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
      <!-- header-util -->
      <div v-if="files.length > 0" class="header-column header-util right">
        <div class="util util-download">
          <a href="javascript:" @click="isFileAtchListShow = true" class="util-actions util-actions-download">
            첨부파일
            <i class="icon-download"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-helpdesk">

    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-view-container helpdesk">
        <div class="view-header">
          <div class="title-wrap">
            <h3 class="title">{{ lib.postTitle }} </h3>
            <p class="subtitle"><span class="text">{{ timestampToDateFormat(lib.regDt, 'yyyy.MM.dd hh:mm') }} 등록</span></p>
          </div>
        </div>
        <div class="view-body">
          <div class="view-content">
            {{ getText(lib.postCn) }}
          </div>
        </div>
      </div>

    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
  <HelpMobileLibFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :fileItems="files"/>
</template>

<script>

import {computed, ref} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {ACT_GET_HELP_FILE_ATCH_LIST, ACT_GET_HELP_LIB} from '@/store/modules/help/help';
import {getItem, getItems, getText, lengthCheck, timestampToDateFormat, convertToStorageBytes} from '@/assets/js/util';
import {ACT_DOWNLOAD_BOARD_ATCH} from '@/store/modules/board/board';
import HelpMobileLibFileAtch from "@/views/pages/help/mobile/HelpMobileLibFileAtch";
import router from "@/router";

export default {
  name: 'HelpMobileLibView',
  components:{
    HelpMobileLibFileAtch,
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    const showAttachments = ref(false);

    const comPostSn = computed(() => route.params.comPostSn);

    const lib = ref({});
    const files = ref([]);
    const isFileAtchListShow = ref(false);

    const getHelpLib = () => {
      if (route.params.comPostSn > 0) {
        store.dispatch(`help/${ACT_GET_HELP_LIB}`, comPostSn.value
        ).then(res => {
          if (lengthCheck(res)) {
            lib.value = getItem(res);
            getHelpNoticeFileAtchList();
          }
        }).catch(e => {
          console.error(e);
        })
      }
    };

    const getHelpNoticeFileAtchList = () => {
      store.dispatch(`help/${ACT_GET_HELP_FILE_ATCH_LIST}`, {
        comPostSn: comPostSn.value
      }).then(res => {
        if (lengthCheck(res)) {
          files.value = getItems(res);
        } else {
          files.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const downloadAtch = (atch) => {
      store.dispatch(`board/${ACT_DOWNLOAD_BOARD_ATCH}`, { atchFileSn: atch.atchFileSn, fileName: atch.fileNm });
    };

    getHelpLib();

    const goBack = () => {
      router.go(-1);
    }


    return {
      showAttachments,
      lib,
      files,
      isFileAtchListShow,
      downloadAtch,
      timestampToDateFormat,
      getText,
      convertToStorageBytes,
      goBack
    }

  }
}
</script>